var finishLoadingCallback = function () {};

$(document).ready(function() {
    
    var preloader = $('main .preloader-wrapper');
    var content = $('main .content');

	$('header .tabs li a').click(function () {
        var href = $(this).attr('href') ? $(this).attr('href')  : '#';
        var disabled = $(this).parent('li').hasClass('disabled');

        if (href[0] == "#" || disabled)
            return ;

        content.addClass('inactive');
        location.href = href;
    });

    function preLoadPage()
    {
        preloader.removeClass('inactive');    
   
        setTimeout(function() {
            preloader.addClass('inactive');
            setTimeout(function () {
                preloader.css('display', 'none');
                content.removeClass('inactive');
                setTimeout(function () {
                    finishLoadingCallback();
                }, 500);
            }, 500);
        }, 1000);
    }

    preLoadPage();

});