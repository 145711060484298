function cookieExists()
{
	return $.cookie('cnil') != null;
}

function consent()
{
	$.cookie('cnil', 'true', { expires: 33696000000 });
	$('.cnil-banner').hide();
	enableGoogleAnalytics();
}

function notConsent()
{
	$.cookie('cnil', 'false', { expires: 390 });
	$('.cnil-banner').hide();
	deleteGoogleAnalyticsCookies();
}

function deleteGoogleAnalyticsCookies()
{
	var cookieNames = ["__utma","__utmb","__utmc","__utmt","__utmv","__utmz","_ga","_gat"]
    for (var i = 0; i < cookieNames.length; ++i)
		$.removeCookie(cookieNames[i]);
}

function enableGoogleAnalytics()
{
	window.dataLayer = window.dataLayer || [];
	function gtag() { dataLayer.push(arguments) };
	gtag('js', new Date());
	gtag('config', 'UA-4425854-1');
}

if (!cookieExists()) {
	$('.cnil-banner').show();

	$(document).mouseup(function(e) 
	{
	    var container = $('.cnil-banner');
	    var infoLink = $('.cnil-banner .info-banner');
	    var closeButton = $('.cnil-banner .close-banner');

	    if (!container.is(e.target) && container.has(e.target).length === 0) 
	        consent();
	    else if (closeButton.is(e.target) || closeButton.has(e.target).length !== 0)
	    	consent();
	    else if (infoLink.is(e.target))
			notConsent();
	});
}
